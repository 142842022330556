<script setup lang="ts">
import type { HTMLAttributes, InputTypeHTMLAttribute } from "vue";
import { inputVariants } from "./input-variants";

const props = withDefaults(
  defineProps<{
    type: InputTypeHTMLAttribute;
    disabled?: boolean;
    position?: "start" | "end" | "center" | "isolated";
    isPasswordVisible?: boolean;
    class?: HTMLAttributes["class"];
  }>(),
  {
    disabled: false,
    position: "isolated",
    isPasswordVisible: false,
    class: "",
  },
);

const modelValue = defineModel<string | number>();
</script>

<template>
  <input
    v-bind="props"
    :type="
      props.type === 'password' && props.isPasswordVisible
        ? 'text'
        : (props.type as string)
    "
    v-model="modelValue"
    autocomplete="off"
    :class="
      cn(
        inputVariants({
          disabled: props.disabled,
          position: props.position,
        }),
        props.class,
      )
    "
  />
</template>
